<div class="p-5">
  <div class="mb-5 mr-3">
    <drdp-title title="Child Requests"></drdp-title>
  </div>

  <drdp-container additionClasses="flex flex-wrap content-between justify-end min-h-[44rem]">
    <div class="w-full">
      <drdp-container color="lightBlue" additionClasses="min-h-[7rem] w-full">
        <form #ngForm="ngForm"
        class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 lg:grid-cols-5 gap-5"
          [formGroup]="childRequestForm"
          (ngSubmit)="handleFormSubmit($event)">

          <drdp-select-state
            #stateSelect
            [initialStateId]="stateId?.value"
            [disabled]="!hasAccessToAllAgencies"
            (state)="handleState($event)"
            [clear]="clearDropdowns"
            [required]="true">
          </drdp-select-state>

          <drdp-select-agency
            #agencySelect
            (agency)="handleAgency($event)"
            [stateId]="stateId.value"
            [initialAgencyId]="agencyId?.value"
            [required]="true"
            [clear]="clearDropdowns">
        </drdp-select-agency>

          <drdp-select-rating-period
            #ratingPeriodSelect
            [initialRatingPeriodId]="ratingPeriodId.value"
            (ratingPeriod)="handleRatingPeriod($event)"
            [agencyId]="agencyId.value"
            [clear]="clearDropdowns">
          </drdp-select-rating-period>

          <drdp-select-site
            #siteSelect
            (site)="handleSite($event)"
            [agencyId]="agencyId.value"
            [multiSelect]="false"
            [required]="false"
            [clear]="clearDropdowns">
          </drdp-select-site>

          <drdp-select-class
            (classroom)="handleClassroom($event)"
            [siteIds]="siteId.value"
            [required]="false"
            [ratingPeriodId]="ratingPeriodId.value"
            [clear]="clearDropdowns"
            [multiSelect]="false">
          </drdp-select-class>

          <drdp-select-request-reason
            #requestResponseSelect
            (onSelected)="handleRequestReason($event)"
            [clear]="clearDropdowns"
            [multiSelect]="false"
            [initialResponseRequestId]="requestReasonId?.value">
          </drdp-select-request-reason>

          <drdp-select-request-status-type
            #requestStatusType
            (onSelected)="handleRequestStatusType($event)"
            [clear]="clearDropdowns"
            [multiSelect]="false"
            [initialRequestStatusTypeId]="requestStatusTypeId?.value">
          </drdp-select-request-status-type>

          <div class="col-span-1 h-full grid space-between">
            <label class="font-bold">Search By:</label>
            <div class="basis-1/2 border-b border-black">
              <input matInput placeholder="Child Name, Classroom Name, Requester Email"
                formControlName="searchString"
                class="w-full bg-drdpblue-200 border-b border-black focus:outline-none focus:border-drdpblue-300">
            </div>
          </div>

          <div class="flex flex-row w-ful">
            <drdp-btn color="outline" (click)="clear()" class="self-end" type="button">
              Clear
            </drdp-btn>
            <drdp-btn class="flex-grow self-end pl-3" type="submit" additionalClasses="w-full">
              <span class="px-2">Search</span>
            </drdp-btn>            
          </div>
        </form>
      </drdp-container>
      <div>
        <drdp-add-edit-table
          class="whitespace-pre-wrap"
          [dataSource]="dataSource"
          [tableData]="tableData"
          [columns]="tableColumns">
        </drdp-add-edit-table>
      </div>
    </div>
  </drdp-container>
  <mat-paginator
    class="mt-5 pb-3"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    [length]="totalData"
    [pageIndex]="pageIndex"
    (page)="paginateTable(false)">
  </mat-paginator>
</div>

