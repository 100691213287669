export enum Permission {
  AdministrativeReport = 187,
  CheckFeature = 118,
  ChildProgressReport = 164,
  ChildReport = 156,
  ClassPlanningReport = 166,
  CohortProgressReport = 163,
  CreateAccounts = 2,
  CreateAgencies = 105,
  CreateClassrooms = 101,
  CreateContactInformations = 20,
  CreateEnrollment = 124,
  CreateFeatures = 26,
  CreateNewsItem = 190,
  DeleteNewsItem = 191,
  CreateOrganizations = 73,
  CreatePermissions = 39,
  CreatePersons = 79,
  CreateRating = 144,
  CreateRatingPeriods = 128,
  CreateRoles = 45,
  CreateSites = 110,
  CreateStates = 108,
  CreateStudent = 116,
  CreateStudentNotes = 114,
  CreateTeachers = 130,
  CreateTerms = 111,
  CreateTitles = 55,
  CreateUserRoles = 8,
  DeleteClassrooms = 153,
  DeleteContactInformations = 19,
  DeleteEnrollments = 152,
  DeleteFeatures = 27,
  DeleteOrganizations = 35,
  DeletePermissions = 38,
  DeletePersons = 42,
  DeleteRatings = 148,
  DeleteRatingView = 318,
  DeleteRoles = 46,
  DeleteSites = 161,
  DeleteTitles = 57,
  DeleteUserRoles = 9,
  DownloadDemographics = 167,
  DownloadDemographicsInclRatings = 175,
  DownloadDemographicsInclRatingsandScores = 177,
  DownloadDemographicsInclScores = 176,
  EditAccountAdministrativeRoles = 142,
  EditAccountContactInformation = 11,
  EditAccountPowerUserRoles = 150,
  EditAccounts = 3,
  EditAccountStatus = 13,
  EditAccountUserRoles = 12,
  EditAgencies = 98,
  EditClassrooms = 99,
  EditContactInformations = 18,
  EditDashboard = 21,
  EditEmailDomains = 34,
  EditEnrollment = 131,
  EditFeatures = 24,
  EditLeadAdminRoles = 160,
  EditOrganizations = 75,
  EditPermissions = 37,
  EditPersons = 78,
  EditRatingPeriods = 126,
  EditRoles = 47,
  EditSites = 97,
  EditStates = 106,
  EditStudents = 132,
  EditTeachers = 96,
  EditTerms = 112,
  EditTitles = 56,
  EditUserName = 151,
  GroupDetailsReport = 174,
  GroupReport = 162,
  GroupReportTNOnly = 157,
  LockRatings = 146,
  ManageStateReports = 179,
  ParentProgressReport = 165,
  RatingCompletion = 158,
  RemoveAllSessions = 31,
  RemoveUserSessions = 29,
  ReportbyTeacher = 159,
  ResetAllUserPasswords = 30,
  ResetUserPassword = 28,
  SearchAccounts = 5,
  SearchAgencies = 137,
  SearchOrganizations = 36,
  SearchPersons = 43,
  SearchSites = 139,
  SearchStudent = 117,
  SearchTeachers = 141,
  SearchUnassignedStudent = 120,
  ShowCompanionDocumentsForDownloadDemographics = 183,
  StateCohortReport = 180,
  StateGroupDetailReport = 184,
  UnlockRatings = 147,
  UploadFile = 134,
  VendorAPIChildLogReport = 188,
  ViewAccountRequests = 15,
  ViewAccountStatusTypes = 10,
  ViewAgencies = 104,
  ViewAllRegions = 14,
  ViewChildTemplates = 168,
  ViewClassrooms = 100,
  ViewClassTemplates = 171,
  ViewContactInformations = 17,
  ViewDRDPResources = 173,
  ViewEnrollment = 125,
  ViewFeatures = 25,
  ViewGlobalAdmin = 32,
  ViewInputRating = 135,
  ViewLockStatus = 178,
  ViewLoginAudits = 6,
  ViewMyClasses = 121,
  ViewNewsItem = 193,
  ViewOrganizations = 74,
  ViewPermissionandRoles = 172,
  ViewPermissions = 40,
  ViewPersons = 77,
  ViewRatingPeriodMenu = 149,
  ViewRatingPeriods = 127,
  ViewRatings = 145,
  ViewReports = 155,
  ViewRoles = 44,
  ViewShoppingCart = 122,
  ViewSites = 109,
  ViewStateReports = 181,
  ViewStates = 107,
  ViewStudent = 119,
  ViewSystem = 84,
  ViewSystemAccounts = 51,
  ViewSystemEntities = 93,
  ViewSystemPersons = 50,
  ViewSystemSurveys = 90,
  ViewSystemWorkflows = 49,
  ViewTeachers = 103,
  ViewTerms = 113,
  ViewTitles = 54,
  ViewUserRoles = 197,
  ViewUserTemplates = 170,
  CreateRatingViews = 198,
  EditRatingViews = 199,
  ViewRatingViews = 200,
  ViewTotalLockedChildren = 203,
  ViewMyRatings = 204,
  ViewObservations = 205,
  ViewPendingTransfers = 206,
  ViewPendingExits = 207,
  ViewPendingReEnrollments = 208,
  ViewLanguageSettings = 209,
  EditLanguageSettings = 210,
  ViewDashboardClassrooms = 211,
  UnlockRatingViews = 258,
  AccessAllAgencies = 262,
  AccessAssignedStateAgencies = 263,
  CreateRatingSpanish = 264,
  AccessAllSites = 266,
  ViewGlobalRatingPeriod = 267,
  AdminEnrollmentByPass = 274,
  HidePendingIndicator = 278,
  CreateBaseTemplates = 283,
  ViewPendingReleaseRequests = 284,
  IntegrationManagement = 277,
  PilotProgramChildrenPage = 290,
  AccessToChildrenPAge = 287,
  SpecialEdChildrenPage = 288,
  HeadStartChildrenPage = 289,
  ViewSharedChild = 269,
  EditObservations = 292,
  DeactivateSites = 294,
  ViewLegacyReports = 295,
  DeactivateRatingPeriod = 297,
  TransferEnrollment = 301,
  EditSameLevelAccounts = 305,
  CreateAllAgencyNewsItem = 306,
  ViewCurrentEnrollment = 307,
  ViewSensitiveStudentTransferInfo = 309,
  EditTOREnrollment = 308,
  EditSameLevelNotPromoteSameLevel = 310,
  AccessToPortfolioMessages = 311,
  AccessToAllPortfolioMessageBoards = 312,
  AccessToReportTracking = 313,
  AccessChildRequestsPage = 314,
  AccessToAssignedChildrenReporting = 319,
  ClassroomSearch = 320,
  ClassReenroll = 321,
  WithdrawEnrollment = 322,
  ManageDualEnrollment = 285,
  PowerUserViewDualEnrollments = 324,
  ViewDualEnrollments = 325,
  DistrictOSEPReport = 327,
  StateOSEPReport = 328,
  SpecialEdExitDateBatch = 329,
  SharedChildAccessDemographicsReport = 335,
  Create2025Classroom = 330,
  Access2025StudentOptions = 331,
  PilotIncludeRatings = 334,
  All2025Pilot = 333,
  PendingPortfolioRequests = 336,
  OSEPException = 337,
  OverrideLockedRatings = 339
}
