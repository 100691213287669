<div class="rounded-lg fix-action-row modal-body">
  <div class="flex justify-between border-b">
    <h1 class="text-[25px] font-bold">{{ label }}</h1>
    <i
      mat-button
      mat-dialog-close
      class="text-drdpblue-300 cursor-pointer uil uil-times-circle"
    ></i>
  </div>
  <div mat-dialog-content>
    <form
      class="grid grid-cols-2 gap-4 gap-x-10 p-3 gridCust"
      [formGroup]="transferChildForm"
    >
      <div *ngIf="isBatchTransfer" class="col-span-2 mt-2">
        <label class="font-normal mb-2" for="childName"
          >Selected Children</label
        >
        <ul>
          <li *ngFor="let child of students">
            {{ child.firstName + " " + child.lastName }}
          </li>
        </ul>
      </div>
      <div *ngIf="!isBatchTransfer" class="col-span-1 mt-2">
        <label class="font-normal mb-2" for="childName">Child's Name</label>
        <p>
          {{ child.firstName + " " + child.lastName }}
        </p>
      </div>
      <div class="col-span-1">
        <drdp-select-rating-period
          [initialRatingPeriodId]="ratingPeriod.value"
          label="Transfer Rating Period"
          (ratingPeriod)="handleRatingPeriod($event)"
          [agencyId]="agencyId"
          [required]="true"
          [omitFutureRps]="true"
        >
        </drdp-select-rating-period>
      </div>
      <div class="col-span-1">
        <drdp-select-site
          [initialSiteId]="site.value"
          label="Transfer to: Site"
          (site)="handleSite($event)"
          [agencyId]="agencyId"
          [multiSelect]="false"
          [multiSelectInitVals]="false"
          [required]="true"
        >
        </drdp-select-site>
      </div>
      <div class="col-span-1">
        <drdp-select-class
          [initialClassroomId]="classroom.value"
          label="Transfer to: Classroom"
          (classroom)="handleClassroom($event)"
          [siteIds]="site.value"
          [ratingPeriodId]="ratingPeriod.value"
          [multiSelect]="false"
          [required]="true"
          [includePilotClasses]="false"
        >
        </drdp-select-class>
      </div>
      <div class="relative">
        <div class="col-span-1">
          <drdp-date-picker label="Classroom Enrollment Date"
            labelClasses="pb-1"
            [isRequired]="true"
            (emitDate)="handleDate($event)">
          </drdp-date-picker>
          <mat-error *ngIf="classEnrollmentDate.hasError('exitDate')" class="text-xs">
            Date cannot be prior to student(s) current enrollment exit date.
          </mat-error>
          <mat-error *ngIf="classEnrollmentDate.hasError('dateRange')" class="text-xs">
            Date must be between rating period's min and max dates.
          </mat-error>
        </div>
        <div class="absolute top-0 left-full mt-1 ml-[-2px]">
          <i class="uil uil-info-circle text-xs" [matTooltip]=enrollmentDateInfo></i>
        </div>
      </div>
      <div class="col-span-2 w-full">
        <mat-form-field class="bg-white" appearance="fill">
          <label for="Comment" class="font-normal block mb-2 text-md">
            Comment
          </label>
          <input
            matInput
            placeholder="Comment"
            type="text"
            formControlName="Comment"
            [maxLength]="150"
          />
        </mat-form-field>
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="flex justify-end">
    <drdp-btn color="cancel" mat-button mat-dialog-close>Cancel</drdp-btn>
    <drdp-btn
      class="ml-3"
      mat-button
      [disabled]="transferChildForm.invalid || isProcessing"
      cdkFocusInitial
      (click)="onSubmit()"
    >
      {{ submitBtnText }}</drdp-btn
    >
  </div>
</div>
