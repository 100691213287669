import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SelectType } from '@core/enums/select';
import { LookupService } from '@core/services/lookup.service';
import { take } from 'rxjs';
import { FilterSelectComponent } from '../filter-select/filter-select.component';

@Component({
  selector: 'drdp-select-request-reason',
  templateUrl: './select-request-reason.component.html',
  styleUrls: ['./select-request-reason.component.scss']
})
export class SelectRequestReasonComponent implements OnInit {
  @ViewChild('requestResponseSelect') requestResponseSelect: FilterSelectComponent | undefined;
  @Input() label: string = 'Reason';
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  @Input() clear: boolean = false;
  @Input() initialResponseRequestId?: number | null;
  @Output() onSelected = new EventEmitter<string | null>();

  studentRequestOptions: any[] = [];
  public select = SelectType;

  constructor(private requestReasonService: LookupService) { }

  ngOnInit(): void {
    this.getRequestReason();
  }

  ngOnChanges(): void {
    if (this.clear && !this.disabled) this.requestResponseSelect?.clear();
  }
  
  getRequestReason(): void {
    this.requestReasonService.getRequestReasons().pipe(take(1)).subscribe((res) => {
      this.studentRequestOptions = res;
    });
  }

  emitSelectedRequestReason(selectedReason: any): void {
    this.onSelected.emit(selectedReason);
  }
}
