import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import {
  IAllStudentsByPermissionRequest,
  IClassStudent,
  IViewClassStudentsRequest,
} from '@core/interfaces/iclasses';
import { IModalEmitData } from '@core/interfaces/imodal';
import { AuthService } from '@core/services/auth.service';
import { ClassService } from '@core/services/class.service';
import { ToastService } from '@core/services/toast.service';
import { Subscription, of, switchMap, take } from 'rxjs';
import { DropChildComponent } from '../../drop-child/drop-child.component';
import { setIconDisplayObject } from '@core/services/helper.service';
import { ITableColumn } from '@core/interfaces/itable';
import { StudentService } from '@core/services/student.service';
import { TransferChildComponent } from '../../transfer-child/transfer-child.component';
import { TableColumnType } from '@core/enums/table';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';
import { SpedExitDateComponent } from './sped-exit-date/sped-exit-date.component';
import { BatchSpedExitDateComponent } from './batch-sped-exit-date/batch-sped-exit-date.component';
import { States } from '@core/enums/states';
import { ConfirmationSpedExitDateComponent } from './confirmation-sped-exit-date/confirmation-sped-exit-date.component';
import { IepPlan } from '@core/enums/iep-plan';
import { StudentClassEnrollmentService } from '@core/services/student-class-enrollment.service';

const tableIconAction = {
  transferChild: 'transferChild',
  dropChild: 'dropChild',
  viewChild: 'viewChild',
};

const studentIconAction = {
  setSpedExitDate: 'setSpedExitDate',
};

@Component({
  selector: 'drdp-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss'],
})
export class StudentsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  private subscriptions = new Subscription();
  classroomName?: string;
  ratingPeriodName?: string;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  tableData!: IClassStudent[];
  pageSizeOptions: number[] = [10, 25, 50, 100];
  totalData: number = 0;
  pageSize: number = 10;
  pageIndex: number = 1;
  payload!: IAllStudentsByPermissionRequest;
  classId!: number;
  showTable = false;
  canBatchUpdateSped = false;
  childMovementErrorMessage: string = 'Child has already been moved.';
  hasAccessToAll = this.permissionService.checkPermission(
    Permission.AccessAllAgencies
  );
  hasAccessToAssigned = this.permissionService.checkPermission(
    Permission.AccessAssignedStateAgencies
  );
  canTransfer = this.permissionService.checkPermission(
    Permission.TransferEnrollment
  );
  canWithdraw = this.permissionService.checkPermission(
    Permission.WithdrawEnrollment
  );
  hasBatchUpdateSpedPermission = this.permissionService.checkPermission(
    Permission.SpecialEdExitDateBatch
  );
  user = this.auth.getCurrentUser();
  agencyId: number = 0;
  siteId: number = 0;
  ratingPeriodId: number = 0;

  tableColumns: ITableColumn[] = [
    {
      columnDef: tableIconAction.viewChild,
      header: "Child's Page",
      type: 'icon',
      icon: 'file',
      cursorClass: 'cursor-pointer',
      colorClass: 'text-drdpblue-300',
    },
    {
      columnDef: 'firstName',
      header: 'First Name',
      type: 'text',
      isSortable: true,
    },
    {
      columnDef: 'lastName',
      header: 'Last Name',
      type: 'text',
      isSortable: true,
    },
    {
      columnDef: 'classEnrollmentDate',
      header: 'Classroom Start Date',
      type: TableColumnType.date,
      isSortable: true,
    },
    {
      columnDef: 'classExitDate',
      header: 'Classroom Withdraw Date',
      type: TableColumnType.date,
      isSortable: true,
    }
  ];

  constructor(
    private classService: ClassService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public modal: MatDialog,
    private auth: AuthService,
    private toast: ToastService,
    private permissionService: PermissionService,
    private enrollmentService: StudentClassEnrollmentService
  ) {}

  ngOnInit(): void {
    if (this.canWithdraw) {
      this.tableColumns.unshift({
        columnDef: tableIconAction.dropChild,
        header: 'Withdraw from Agency',
        type: 'icon',
        icon: 'user-minus',
        cursorClass: 'cursor-pointer',
        colorClass: 'text-drdpblue-300',
      })
    }
    if (this.canTransfer) {
      this.tableColumns.unshift({
        columnDef: tableIconAction.transferChild,
        header: 'Transfer',
        type: 'icon',
        icon: 'sign-in-alt',
        cursorClass: 'cursor-pointer',
        colorClass: 'text-drdpblue-300',
      })
    }
    this.getClassIdFromUrl();
    this.createInitialPayload();
    this.setClassroomAndRatingPeriodName();
    this.getClassStudents(this.payload);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '';
  }

  createInitialPayload() {
    this.payload = {
      classId: this.classId,
      itemsPerPage: 10,
      pageIndex: 1,
    };
  }

  setClassroomAndRatingPeriodName(): void {
    this.classService
      .getStudentsByClass(this.classId)
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res) {
          this.classroomName = res.classroom.name;
          this.ratingPeriodName = res.ratingPeriod.ratingPeriodName;
          this.ratingPeriodId = res.ratingPeriod.id;
          this.agencyId = res.classroom.agencyId;
          this.siteId = res.siteId;
          if (res.stateId == States.newHampshire) {
            this.canBatchUpdateSped = this.hasBatchUpdateSpedPermission;
            this.tableColumns.push({
              columnDef: 'spedAnticipatedExitDate',
              header: 'Anticipated Special Education Exit Date',
              type: TableColumnType.date,
            });
            this.tableColumns.push({
              columnDef: 'spedExitDate',
              header: 'Special Education Exit Date',
              type: TableColumnType.date,
            });
            this.tableColumns.push({
              columnDef: studentIconAction.setSpedExitDate,
              header: '',
              type: TableColumnType.actionIcon,
              action: studentIconAction.setSpedExitDate,
              icon: 'calendar-alt',
              cursorClass: 'cursor-pointer',
              colorClass: 'text-drdpblue-300',
            });
          }

          this.tableColumns.push({
            columnDef: 'isLocked',
            header: 'Is Locked',
            type: TableColumnType.boolIcon,
            truthyClass: 'text-drdpGreen-300',
            truthyIconUrl: 'assets/images/misc/lock-alt.svg',
            falsyClass: 'text-drdpgrey-200',
            falsyIconUrl: 'assets/images/misc/padlock.svg',
            isSortable: true,
          });
          this.showTable = true;
        }
      });
  }

  getClassStudents(payload: any): void {
    this.classService
      .getAllClassStudentsByPermissions(this.payload)
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res) {
          res.items = res.items.map((data: any) => ({
            ...data,
            displayIcons: setIconDisplayObject(this.tableColumns, data),
          }));
          this.dataSource = new MatTableDataSource(res.items);
          this.tableData = res.items;
          this.totalData = res.totalData;
        }
      });
  }

  getClassIdFromUrl(): void {
    this.classId = Number(this.activatedRoute.snapshot.paramMap.get('id'));
  }

  paginate(event: any): void {
    const payload = this.payload;
    payload.itemsPerPage = event.pageSize;
    payload.pageIndex = event.pageIndex + 1;

    this.getClassStudents(payload);
  }

  onIconClick(event: any): void {
    event.data.classId = this.classId;
    event.data.agencyId = this.agencyId;
    event.data.ratingPeriodId = this.ratingPeriodId;
    event.data.siteId = this.siteId;
    switch (event.type) {
      case tableIconAction.transferChild:
        this.transferChildModal(event);
        break;
      case tableIconAction.dropChild:
        this.dropChildModal(event);
        break;
      case tableIconAction.viewChild:
        if (
          (!this.hasAccessToAll &&
            !this.hasAccessToAssigned &&
            event.data.enrollmentEndDate) ||
          (this.hasAccessToAssigned && this.user.stateId != event.data.stateId)
        ) {
          this.toast.error('Child is no longer with your agency.');
          return;
        }
        this.router.navigate([
          `/view-child/${event.data.id}/class/${this.classId}`,
        ]);
        break;
      default:
        break;
    }
  }

  transferChildModal(event: IModalEmitData) {
    if (event.data.classExitDate) {
      this.toast.error(this.childMovementErrorMessage);
      return;
    } else if (event.data?.id <= 0) {
      this.toast.error('No Student Data Present');
      return;
    }
    
    this.enrollmentService.areAllEnrollmentsActive([event.data.studentClassEnrollmentId]).pipe(
      take(1),
      switchMap((isActive: boolean) => {
        if (isActive) {
          const modalRef = this.modal.open(TransferChildComponent, {
            data: event ? event : undefined,
          });
          return modalRef.afterClosed();
        } else {      
          this.toast.error(this.childMovementErrorMessage);
          return of(false);          
        }
      })
    ).subscribe((success) => {
      if (success) {
        this.getClassStudents(this.payload);
      }
    });
  }

  dropChildModal(event: IModalEmitData) {
    if (event.data.classExitDate) {
      this.toast.error(this.childMovementErrorMessage);
      return;
    } else if (event.data?.id <= 0) {
      this.toast.error('No Student Data Present');
      return;
    }

    this.enrollmentService.areAllEnrollmentsActive([event.data.studentClassEnrollmentId]).pipe(
      take(1),
      switchMap((isActive: boolean) => {
        if (isActive) {
          const modalRef = this.modal.open(DropChildComponent, {
            data: event ? event : undefined,
          });
          return modalRef.afterClosed();
        } else {      
          this.toast.error(this.childMovementErrorMessage);
          return of(false);          
        }
      })
    ).subscribe((success) => {
      if (success) {
        this.getClassStudents(this.payload);
      }
    });
  }

  beginBatchUnEnrollment() {
    this.router.navigate([`/view-students/${this.classId}/bulk-un-enrollment`]);
  }

  batchSpedExit() {
    const eligibleStudents = this.tableData.filter((student: IClassStudent) => 
      student.isLocked === true && 
      student.spedEntryDate != null && 
      !student.osepQueueId &&
      student.iepPlanId === IepPlan.Yes &&
      student.districtOfLiabilityId);

    if(eligibleStudents.length === 0) {
      this.toast.error(
        `There are no children in this class that are eligible for setting a Special Education Exit Date.  The Special Education Exit Date can only be added for children 
        with locked ratings, is noted as a child with an IEP and with a Special Education Entry Date, and has a District of Liability chosen on their child demographics.`
      );
      return;
    }
    const modalRef = this.modal.open(BatchSpedExitDateComponent, {
      data: eligibleStudents ? eligibleStudents : undefined,
    });
    modalRef.afterClosed().subscribe((success) => {
      if (success) {
        this.getClassStudents(this.payload);
      }
    });
  }

  onActionItemClicked(event: any): void {
    switch (event.action) {
      case studentIconAction.setSpedExitDate:
        this.setSpedExitDateModal(event);
        break;
      default:
        break;
    }
  }

  setSpedExitDateModal(event: IModalEmitData) {
    if (event.data.iepPlanId != IepPlan.Yes) {
      this.toast.error(
        'Child must be in Special Education.'
      );
      return;
    }

    if(!event.data.districtOfLiabilityId) {
      this.toast.error(
        'Child must have a District of Liability chosen on their Demographics.'
      );
      return;
    }

    if (!event.data.isLocked) {
      this.toast.error(
        'Special Education Exit Date can only be set for locked students.'
      );
      return;
    }

    if (event.data.osepQueueId) {
      this.toast.error(`Child's Special Education Date has already been submitted.`);
      return;
    }

    if (event.data.id > 0) {
      const modalRef = this.modal.open(SpedExitDateComponent, {
        data: event ? event : undefined,
      });
      modalRef.afterClosed().subscribe((success) => {
        if (success) {
          this.getClassStudents(this.payload);
        }
      });
    } else {
      this.toast.error('No Student Data Present');
    }
  }

  openConfirmationModal() {
  this.modal.open(ConfirmationSpedExitDateComponent, {
    data:null,
    }
  )
  }

  onDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
