import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Permission } from '@core/enums/permissions';
import { SelectType } from '@core/enums/select';
import { StudentDemographic } from '@core/enums/student-demographic-form';
import { IModalEmitData } from '@core/interfaces/imodal';
import { IStateConfiguration } from '@core/interfaces/istate-configurations';
import {
  IStudentSearchRequestDto,
  IStudentSearchResponseDto,
} from '@core/interfaces/istudent';
import { AuthService } from '@core/services/auth.service';
import { PermissionService } from '@core/services/permission.service';
import { StateService } from '@core/services/state.service';
import { StudentService } from '@core/services/student.service';
import { ToastService } from '@core/services/toast.service';
import { Observable, map, of, switchMap, take, tap } from 'rxjs';
import { TransferChildComponent } from '../transfer-child/transfer-child.component';
import { DropChildComponent } from '../drop-child/drop-child.component';
import { BatchReEnrollChildComponent } from '../batch-re-enroll-child/batch-re-enroll-child.component';
import { Modal } from '@core/enums/modal';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { ITableColumn } from '@core/interfaces/itable';
import { setIconDisplayObject } from '@core/services/helper.service';
import { StudentClassEnrollmentService } from '@core/services/student-class-enrollment.service';

@Component({
  selector: 'drdp-search-manage-child',
  templateUrl: './search-manage-child.component.html',
  styleUrls: ['./search-manage-child.component.scss'],
})
export class SearchManageChildComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  childSearch: FormGroup | any;
  user = this.authService.getCurrentUser();
  isSuperUser: boolean = this.authService.isSuperUser();
  isSiteAdmin: boolean = this.authService.isSiteAdmin();
  isTeacher: boolean = this.authService.isTeacher();
  clearDropdowns = false;

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageIndex: number = 0;
  pageSize: number = 10;
  totalData = 0;
  tableData?: any;
  ssidLabel = 'SSID';
  ssidPlaceholder = 'SSID';
  isSelectAll = false;
  studentBatch: IStudentSearchResponseDto[] = [];
  selectAllPagesSet: Set<number> = new Set<number>();
  transferInfoText: string = "Transfer moves children to a different classroom with a different classroom name within your agency, for example, transfer from Room A to Room B.";
  reEnrollInfoText: string = "Re-enroll moves children into the same classroom (the classroom name must be an exact match) in a new rating period.  For example, re-enroll from Room A (in the fall) to Room A (in the spring).";
  withdrawInfoText: string = "Withdraw from Agency leaves the child's history intact while removing the child from your agency. It also makes them available for other agencies to enroll the child.";
  initialAgencySetupComplete: boolean = false;
  initialSiteSetupComplete: boolean = false;

  private previousAgencyId: number | null = null;
  private previousRatingPeriodId: number | null = null;

  get firstName() {
    return this.childSearch.get('firstName');
  }
  get lastName() {
    return this.childSearch.get('lastName');
  }
  get stateId() {
    return this.childSearch.get('stateId');
  }
  get agencyId() {
    return this.childSearch.get('agencyId');
  }
  get SSID() {
    return this.childSearch.get('SSID');
  }
  get localId() {
    return this.childSearch.get('localId');
  }
  get ageGradeInstrumentId() {
    return this.childSearch.get('ageGradeInstrumentId');
  }
  get DOB() {
    return this.childSearch.get('DOB');
  }
  get drdpId() {
    return this.childSearch.get('drdpId');
  }
  get ratingPeriodId() {
    return this.childSearch.get('ratingPeriodId');
  }
  get classroomIds() {
    return this.childSearch.get('classroomIds');
  }
  get siteIds() {
    return this.childSearch.get('siteIds');
  }

  tableColumns: ITableColumn[] = [
    {
      columnDef: 'isSelected',
      header: 'Select',
      type: 'checkBox',
      checkClass: 'checkbox accent-drdpblue-300 cursor-pointer',
    },
    { columnDef: 'lastName', header: 'Last Name', type: 'text' },
    { columnDef: 'firstName', header: 'First Name', type: 'text' },
  ];

  public get selectType() {
    return SelectType;
  }
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private studentService: StudentService,
    private toastService: ToastService,
    private permissionService: PermissionService,
    private stateService: StateService,
    private router: Router,
    public modal: MatDialog,
    private enrollmentService: StudentClassEnrollmentService
  ) {}

  ngOnInit(): void {
    if (this.permissionService.checkPermission(Permission.ViewDualEnrollments)) {
      this.tableColumns.push(
        {
          columnDef: 'isDualEnrollment',
          header: 'Dual Enrollment',
          type: 'icon',
          icon: 'users-alt',
          colorClass: 'text-drdpblue-300', 
          cursorClass: 'cursor-text'
        }
      );
    }
    this.tableColumns.push(
      { columnDef: 'dob', header: 'DOB', type: 'date' },
      { columnDef: 'siteName', header: 'Site', type: 'text' },
      { columnDef: 'className', header: 'Class', type: 'text' },
      { columnDef: 'teacherOfRecordName', header: 'Teacher', type: 'text' },
      {
        columnDef: 'enrollmentStartDate',
        header: 'Agency Enrollment',
        type: 'date',
      },
      {
        columnDef: 'enrollmentEndDate',
        header: 'Agency Withdrawal',
        type: 'date',
      },
      {
        columnDef: 'ageGradeInstrumentName',
        header: 'Age/Instrument',
        type: 'text',
      },
      { columnDef: 'View Student', header: 'View Student', type: 'view' },
    );

    this.getSsidLabel(this.user.stateId);
    this.initializeForm();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '';
  }

  initializeTableData(res?: any): void {
    res.items = res.items.map((data: any) => ({
      ...data,
      displayIcons: setIconDisplayObject(this.tableColumns, data)
    }));
    this.dataSource = new MatTableDataSource(res.items);
    this.totalData = res.totalData;
    this.tableData = res.items;
  }

  initializeForm(): void {
    this.childSearch = this.fb.group({
      firstName: [null, [Validators.maxLength(100)]],
      lastName: [null, [Validators.maxLength(100)]],
      stateId: [null],
      agencyId: [null, [Validators.required]],
      ageGradeInstrumentId: [null],
      SSID: [null, [Validators.maxLength(100)]],
      localId: [null, [Validators.maxLength(100)]],
      drdpId: [null, [Validators.maxLength(100)]],
      DOB: [null],
      ratingPeriodId: [null],
      classroomIds: [null],
      siteIds: [null, [Validators.required]],
    });

    const { stateId, agencyId, sites } = this.user;

    if (stateId) this.childSearch.patchValue({ stateId: stateId });
    if (agencyId) this.childSearch.patchValue({ agencyId: agencyId });
    if (sites) {
      const siteIds = sites?.map((sites: any) => sites.siteId);
      this.childSearch.patchValue({ siteIds: siteIds });
    }
  }

  getSsidLabel(stateId: number): void {
    this.stateService
      .getStateConfigurations(stateId)
      .pipe(
        take(1),
        tap((config: IStateConfiguration[]) => {
          const ssid = config.find(
            (x: any) => x.columnName === StudentDemographic.SSID
          );
          this.ssidLabel = ssid?.label ? ssid?.label : this.ssidLabel;
          this.ssidPlaceholder = ssid?.label ? ssid?.label : this.ssidLabel;
        })
      )
      .subscribe();
  }

  handleDropdown(event: any, type: string): void {
    switch (type) {
      case SelectType.States:
        this.stateId.setValue(event?.id);
        this.agencyId.setValue(null);
        this.siteIds.setValue(null);
        this.ratingPeriodId.setValue(null);
        this.classroomIds.setValue(null);
        break;
      case SelectType.Agencies:
        this.agencyId.setValue(event?.id);
        this.ratingPeriodId.setValue(null);
        if (this.initialAgencySetupComplete && this.initialSiteSetupComplete) 
          this.siteIds.setValue(null);
        this.classroomIds.setValue(null);
        this.initialAgencySetupComplete = true;
        break;
      case SelectType.Sites:
        const siteIds = event?.map((sites: any) => sites.id);
        this.siteIds.setValue(siteIds);
        this.classroomIds.setValue(null);
        this.initialSiteSetupComplete = true;
        break;
      case SelectType.RatingPeriods:
        this.ratingPeriodId.setValue(event?.id);
        break;
      case SelectType.Class:
        const classroomIds = event?.map((classroom: any) => classroom.id);
        this.classroomIds.setValue(classroomIds.length ? classroomIds : null);
        break;
      case SelectType.AgeInstrument:
        this.ageGradeInstrumentId.setValue(event?.id);
        break;
    }
  }

  handleDate(event: any): void {
    this.DOB.setValue(event);
  }

  handleCheckbox(event: any): void {
    if (!this.isSuperUser && event.data.enrollmentEndDate) {
      event.event.target.checked = false;
      this.toastService.error('Child is no longer enrolled with your agency.');
      return;
    }
    const { data } = event;
    data.isSelected = !data.isSelected;

    const index = this.studentBatch.findIndex(
      (student) => (student.id === data.id && student.classId === data.classId)
    );

    if (data.isSelected) {
      if (index === -1) {
        this.studentBatch.push(data);
      }
    } else {
      if (index !== -1) {
        this.studentBatch.splice(index, 1);
        if (this.selectAllPagesSet.has(this.paginator.pageIndex)) {
          this.isSelectAll = false;
          this.selectAllPagesSet.delete(this.paginator.pageIndex);
        }
      }
    }
  }

  search(): void {
    this.paginateTable(true);
  }

  onClear(): void {
    this.initialAgencySetupComplete = false;
    this.initialSiteSetupComplete = false;
    this.clearDropdowns = true;
    const accessToAll = this.permissionService.checkPermission(
      Permission.AccessAllAgencies
    );
    this.tableData = [];
    this.initializeTableData(this.tableData);

    if (accessToAll) {
      this.childSearch.reset();
    } else {
      const stateId = this.stateId.value;
      const agencyId = this.agencyId.value;

      this.childSearch.reset({
        stateId: stateId,
        agencyId: agencyId,
      });
    }
  }

  paginateTable(resetPage: boolean): void {
    if (this.childSearch.invalid) {
      this.toastService.error('Please enter all required fields.');
      return;
    }

    if (resetPage) this.studentBatch.length = 0;

    const currentAgencyId = this.agencyId.value;
    const currentRatingPeriodId = this.ratingPeriodId.value;

    if (
      currentAgencyId !== this.previousAgencyId ||
      currentRatingPeriodId !== this.previousRatingPeriodId
    ) {
      this.studentBatch = [];
      this.isSelectAll = false;
    }

    this.previousAgencyId = currentAgencyId;
    this.previousRatingPeriodId = currentRatingPeriodId;

    const siteIds = this.siteIds.value?.length
      ? this.siteIds.value.toString()
      : null;
    const pageIndex = resetPage ? 0 : this.paginator.pageIndex + 1;
    const itemsPerPage = resetPage ? 10 : this.paginator.pageSize;

    const payload: IStudentSearchRequestDto = {
      ...this.childSearch.value,
      siteIds,
      pageIndex,
      itemsPerPage,
    };

    this.studentService
      .search(payload)
      .pipe(
        take(1),
        tap((response: any) => {
          if (resetPage) {
            this.paginator.firstPage();
          }
          const { children } = response;
          const selectedBatchIds = this.studentBatch.map((batch) => batch.id);

          children.items.forEach((student: IStudentSearchResponseDto) => {
            student.isSelected = selectedBatchIds.includes(student.id);
          });

          this.initializeTableData(children);

          if (children.totalData > 25) {
            this.pageSizeOptions.push(response.totalData);
          }

          this.isSelectAll = false;
        })
      )
      .subscribe();
  }

  selectAllStudents(): void {
    if (this.selectAllPagesSet.has(this.paginator.pageIndex)) {
      this.isSelectAll = false;
      this.selectAllPagesSet.delete(this.paginator.pageIndex);
    } else {
      this.isSelectAll = true;
    }

    if (this.tableData) {
      this.tableData.forEach((student: any) => {
        student.isSelected = this.isSelectAll;
      });

      if (this.isSelectAll) {
        this.tableData.forEach((student: any) => {
          const index = this.studentBatch.findIndex(
            (s: any) => s.id === student.id
          );
          if (index === -1) {
            this.studentBatch.push(student);
          }
          this.selectAllPagesSet.add(this.paginator.pageIndex);
        });
      } else {
        this.studentBatch = this.studentBatch.filter((student: any) =>
          !this.tableData.some((tb: any) => tb.id == student.id)
        );
      }
    }
  }

  private handleActiveCheckAndOpenModal(modalComponent: any, modalData: any): Observable<any> {
    const enrollmentIds = this.studentBatch
      .map(student => student.sceId)
      .filter((id): id is number => id !== undefined && id !== null);

      return this.enrollmentService.areAllEnrollmentsActive(enrollmentIds).pipe(
        take(1),
        switchMap((isActive: boolean) => {
          if (isActive) {
            const modalRef = this.modal.open(modalComponent, {
              data: modalData,
            });
            return modalRef.afterClosed();
          } else {
            this.toastService.error('One or more children selected has already been moved.');
            return of(false);
          }
        })
      );
  }

  batchTransfer(): void {
    if (this.studentBatch.length < 1) {
      this.toastService.error('No students to Transfer.');
      return;
    }

    const batchData = {
      studentBatch: this.studentBatch,
      agencyId: this.agencyId.value,
    };
    
    this.handleActiveCheckAndOpenModal(TransferChildComponent, batchData).subscribe((response) => {
      if (response.success) {
        this.paginateTable(true);
      } else if (response.duplicates) {
        this.showDuplicateModal(response.duplicates, 'transfer');
      }
    });
  }

  batchReEnroll(): void {
    if (this.studentBatch.length < 1) {
      this.toastService.error('No students to Re-Enroll.');
      return;
    }
    const batchClassroom = [
      ...new Set(this.studentBatch.map((student) => student.classId)),
    ];

    if (batchClassroom.length > 1) {
      let event = { data: { modalInfo: {} } };
      event.data.modalInfo = {
        title: '',
        message: 'One or more children selected are not from the same classroom. This feature re-enrolls a classroom of children to future rating period.',
        name: '',
        primaryBtnClass: 'blue',
        primaryBtnText: Modal.OK,
      };
      const modalRef = this.modal.open(ConfirmationModalComponent, {
        data: event ? event : undefined,
      });
      modalRef.afterClosed().subscribe();
      return;
    }

    const batchData = {
      students: this.studentBatch,
      name: this.studentBatch[0].className,
      classroomId: this.studentBatch[0].classroomId,
      fromClassId: batchClassroom[0],
      ratingPeriodId: this.studentBatch[0].ratingPeriodId,
      agencyId: this.agencyId.value
    };

    this.handleActiveCheckAndOpenModal(BatchReEnrollChildComponent, batchData).subscribe((response) => {
      if (response.success) {
        this.paginateTable(true);
      } else if (response.duplicates) {
        this.showDuplicateModal(response.duplicates, 're-enroll');
      }
    });
  }

  batchWithdraw(): void {
    if (this.studentBatch.length < 1) {
      this.toastService.error('No students to Withdraw.');
      return;
    }

    const batchData = {
      students: this.studentBatch,
      isBatch: true,
    };

    this.handleActiveCheckAndOpenModal(DropChildComponent, batchData).subscribe((response) => {
      if (response.success) {
        this.paginateTable(true);
      } else if (response.duplicates) {
        this.showDuplicateModal(response.duplicates, 'withdraw');
      }
    });
  }

  showDuplicateModal(students: string[], type: string) {
    let modal = { data: { modalInfo: {} } };

    let studentList = '<ul>';

    students.forEach((s) => {
      studentList += `<li> - ${s}</li>`;
    });
    studentList += '</ul>';
    modal.data.modalInfo = {
      title: `Error`,
      message: `There is one or more child requests already pending. Please remove from your ${type} request. <br> <br> ${studentList}`,
      name: 'Error',
      primaryBtnText: Modal.OK,
    };

    const modalRef = this.modal.open(ConfirmationModalComponent, {
      data: modal,
    });

    modalRef.afterClosed().subscribe((res) => {
      if (res) {
        modalRef.close({ success: false });
      }
    });
  }

  openViewChild(viewData: IModalEmitData): void {
    if (!this.isSuperUser && viewData.data.enrollmentEndDate) {
      this.toastService.error('Child is no longer enrolled with your agency.');
      return;
    }
    this.router.navigate(['/view-child/' + viewData.data.id]);
  }
}
